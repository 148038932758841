<template>
  <div class="collectionsView">
    <van-sticky>
      <van-nav-bar :title="$t('绑定银行卡')" left-arrow @click-left="onClickLeft" />
    </van-sticky>
    <div class="list">
      <div class="btn">
        <van-button color="#1989fa" @click="addCollections" block>{{$t('添加银行卡')}}</van-button>
      </div>
      <van-cell v-for="(item, key) in list" :key="key" class="xiax_item" @click="onDetail(item.id)">
        <div class="left">
          <van-icon name="card" class="svg" size="1.4rem" color="#4087f1" />
        </div>
        <div class="center">
          <span class="bank">
            <em>{{ item.bank_name }}</em>
          </span>
          <br />
          <span class="account">
            <em>{{ item.account }}</em>
          </span>
        </div>
        <div class="right">
          <van-icon size="0.7rem" name="arrow" />
        </div>
      </van-cell>
    </div>
  </div>
</template>

<script>
import { collectionsLists } from "@/api/collections";
export default {
  data() {
    return {
      list: []
    };
  },
  created() {
    this.getCollectionsLists();
  },
  methods: {
    onDetail(id) {
      this.$router.push({
        path: "/main/user/addCollections",
        query: { id: id }
      });
    },
    onClickLeft() {
      this.$router.push("/main/user/setting");
    },
    //获取收款方式列表
    getCollectionsLists() {
      collectionsLists({ page: this.page, pageSize: this.pageSize })
        .then(response => {
          this.list = response.result;
        })
        .catch(error => {});
    },
    addCollections() {
      this.$router.push("/main/user/addCollections");
    }
  }
};
</script>
<style lang="stylus">
.collectionsView
  .list
    margin-top: 0.3rem
    .xiax_item
      display: flex
      padding: 0.15rem 0.4rem
      background-color: $cellBackColor
      .van-cell__value
        display: flex
        .left
          display: flex
          align-items: center
          margin-right: 0.3rem
          .svg
            font-size: 0.8rem
        .center
          flex: 1
          font-size: 0.36rem
          padding: 0.2rem
          .bank
            font-weight: 500
            color: #bbb
          .account
            color: #888
            font-size: 0.4rem
        .right
          color: #c7bcbc
          display: flex
          align-items: center
          justify-content: center
  .btn
    width: 95%
    margin: 0 auto 0.5rem
    .van-button
      border-radius: 0.2rem
      .van-button__text
        font-size: 0.42rem
</style>
